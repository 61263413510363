import { DataFileTree } from './DataFileTree';
import { GroupServiceModeIncidentPhoto } from './GroupServiceMode';
import { GroupSettingsIntervalControl, ItemThingType, QrCodeInformation } from './GroupSettings';
import { UpdateGroupSettingsByObjectIdModel } from './UpdateGroupSettingsByObjectIdModel';
import { UserProperty } from './UserProperty';
import { ImportanceLevel, MaterialStock } from './UserStock';
import { UserType } from './UserType';

export enum ObjectDisplayType {
    Standard = 0,
    DescriptionCostLocation = 1
}

export enum QMRatingType {
    QMStandard = 0,
    QMWeighting = 1,
    QMErrorPointsCount = 2,
}


export class ObjectUnit {

    public id: string = '';
    public systemETag: string = '';
    public systemTimestamp: string = '';
    public name: string = '';
    public addressLine!: string;
    public city!: string;
    public state!: string;
    public zipCode!: string;
    public country!: string;
    public objectNumber !: string;
    public description !: string;
    public costLocation !: string;
    public objectDisplayType !: ObjectDisplayType;


    public customerName !: string;
    public isActivatedEnabled! : boolean;
    public isQrCodeSignInForcedEnabled!: boolean;
    public isQrCodeSignOutForcedEnabled!: boolean;
    public isQrCodeGroupSignInObjectLeaderForcedEnabled!: boolean;
    public userId! : string;
    public userServiceWorkerIds !: string[];
    public notificationSettings !: NotificationSettingsObjectUnit;
    public intervalControl !: GroupSettingsIntervalControl;
    public isDailyReportEnabled !: boolean;
    public standaloneLicenseValidUntilTimestamp !: string;
    public updateGroupSettings! : UpdateGroupSettingsByObjectIdModel;

    public qrCodeInformation !: QrCodeInformation;


    public serviceWorkerCountCarbonDioxideTypes : ServiceWorkerCountCarbonDioxideType[] = [];

    public dataFileTree : DataFileTree[] = [];

    public materials : MaterialObjectUnit[] = [];

    public qmSettings !: QMSettings;

    public userPropertyIds : string[] = [];



    static IsStandaloneLicenseValid = (object: ObjectUnit) => {
        return new Date(object.standaloneLicenseValidUntilTimestamp) >= new Date();
    }

    static Columns = {
        id: 'id',
        name: "name",
        customerName: "customerName",
        addressLine: "addressLine",
        city: "city",
        state: "state",
        zipCode: "zipCode",
        country: "country",
        isActivatedEnabled: "isActivatedEnabled",
        actions: "actions",
        actions2: "actions2",
        folder: 'folder',
        groups: 'groups',
        devices: 'devices',
        assign: 'assign',
        isDailyReportEnabled: 'isDailyReportEnabled',
        standaloneLicenseValidUntilTimestamp: 'standaloneLicenseValidUntilTimestamp',
        costLocation: 'costLocation',
        description: 'description',
        objectNumber: 'objectNumber',
        objectDisplayType: 'objectDisplayType',
        serviceWorkerCountCarbonDioxideType: 'serviceWorkerCountCarbonDioxideTypes',
        materials : "materials",
        alarmEmailMaterialStock: 'alarmEmailMaterialStock',
        userProperties : 'userProperties'

    }

    constructor (){
    }

    static CreateObject = () => {
        const object = new ObjectUnit();
        object.intervalControl = new GroupSettingsIntervalControl();
        object.qmSettings = new QMSettings();
        object.intervalControl.isIntervalControlEnabled = false;
        object.objectDisplayType = ObjectDisplayType.Standard;
        object.isActivatedEnabled = true;
        object.isQrCodeSignInForcedEnabled = false;
        object.isQrCodeSignOutForcedEnabled = false;
        object.isQrCodeGroupSignInObjectLeaderForcedEnabled = false;

        return object;
    }
}

export class QMSettings {
    constructor() {
        this.qmIntervalControl = new GroupSettingsIntervalControl();
        this.qmRatingType = QMRatingType.QMWeighting;
        this.qmPotSize = 100;
    }
    public qmRatingType : QMRatingType = QMRatingType.QMStandard;
    public qmPotSize !: number; 
    public qmIntervalControl !: GroupSettingsIntervalControl;

    public qmGradeErrorPointsSettings !:  QMGradeErrorPointsSettings[];

    static AvailableTypes = [
        { value: QMRatingType.QMStandard, label: 'Standard' },
        { value: QMRatingType.QMWeighting, label: 'myObjectSettings.weighting%' },
        { value: QMRatingType.QMErrorPointsCount, label: 'myObjectSettings.errorPoints' }
    ];
    static GetLabelByType = (type: QMRatingType | undefined, t: any) => QMSettings.AvailableTypes.filter(x => x.value === type).map(x => t(x.label))[0] || '';
}

export class QMGradeErrorPointsSettings {
    minAreaSize !: number;
    maxAreaSize !: number;

    qmGradeItemThing !: QMGradeItemThing[];

    constructor (minAreaSize : number, maxAreaSize : number, qmGradeItemThing : QMGradeItemThing[]){
        this.minAreaSize = minAreaSize;
        this.maxAreaSize = maxAreaSize;
        this.qmGradeItemThing = qmGradeItemThing;
    }


}

export class QMGradeItemThing {
    type !: ItemThingType;

    grade5 !: number;
    grade4 !: number;
    grade3 !: number;
    grade2 !: number;
    grade1 !: number;

    constructor (type : ItemThingType, grade5 : number, grade4 : number, grade3: number, grade2: number, grade1: number){
        this.type = type;
        this.grade5 = grade5;
        this.grade4 = grade4;
        this.grade3 = grade3;
        this.grade2 = grade2;
        this.grade1 = grade1;

    }

}

export class NotificationSettingsObjectUnit {
    serviceWorkerAbscenceEmail : string = "";
    serviceWorkerAbscenceNotificationEnabled : boolean = false;
    presenceTooLowEmail !: string;
    presenceTooLowEmailNotificationEnabled !: boolean;
    presenceDurationTooLowEmail !: string;
    presenceDurationTooLowNotificationEnabled : boolean = false;

    dailyReportServiceWorkerPresenceEmail : string = "";
    dailyReportServiceWorkerPresenceNotificationEnabled : boolean = false;
    public alarmEmailMaterialStock !: string;


}


export class ServiceWorkerCountCarbonDioxideType {
    public name: string = "" ;
    public percent : number = 0;
    public serviceWorkerId : string = "";


    static mapPercent = (worker : ServiceWorkerCountCarbonDioxideType) => {
        switch (worker.name){
            case "Auto":
                worker.percent = 100;
                break;
            case "Zu Fuß":
                worker.percent = 0;
                break;
            case "Öffentlich":
                worker.percent = 50;
                break;
        }
    }
}

export class MaterialObjectUnit {
    public materialId: string = "" ;
    public amount: number = 0;
    public specificReason !: string;
    public minimumAmount : number = 0;
    public maximumAmount : number = 0;
    public importanceLevelObject !: ImportanceLevel;

    public alarmEmail : string = "";

    //only frontend
    public text !: string;
    public article !: string;
    public entity !: string;
    public photos !: GroupServiceModeIncidentPhoto[];
    public createdTimestamp !: string;
    public diffAmount : number = 0;
    public newAmount !: number;
    ///////////////////

    public constructor (materialId: string, amount: number, specificReason: string){
        this.materialId = materialId;
        this.amount = amount;
        this.specificReason = specificReason;
    }

    public static CreateInstanceFrontend(materialId: string, amount: number, photos: GroupServiceModeIncidentPhoto[], createdTimestamp: string, text: string, entity: string, article: string){
        let ret = new MaterialObjectUnit(materialId, amount, "");
        ret.photos = photos;
        ret.createdTimestamp = createdTimestamp;
        ret.text = text;
        ret.article = article;
        ret.entity = entity;
        return ret;
    }

    public static SetValuesFromMaterialStock(material: MaterialStock, standardMaterial : MaterialObjectUnit){
        standardMaterial.amount = material.currentObjectStockAmount;
        standardMaterial.newAmount = material.currentObjectStockAmount;
        standardMaterial.minimumAmount = material.minimumObjectAmount || 0;
        standardMaterial.maximumAmount = material.maximumObjectAmount || 0;
        standardMaterial.importanceLevelObject = material.importanceLevelObject || 0;


        standardMaterial.diffAmount = 0;
        standardMaterial.alarmEmail = material.alarmEmailObject;
        return standardMaterial;
    }

    public static CreateInstanceFromUserStock(material: MaterialStock){
        let ret = new MaterialObjectUnit(material.materialId, material.currentObjectStockAmount, "");
        return ret;
    }

    static Columns = {
        id: 'id',
        creatorUserId: 'creatorUserId',
        text: 'text',
        createdTimestamp: 'createdTimestamp',
        activated: 'activated',
        photoContent: 'photoContent',
        itemType: 'itemType',
        actions: 'actions',
        isAdminItem : 'isAdminItem',
        amount: 'amount',
        diffAmount: 'diffAmount',
        newAmount: 'newAmount',
        specificReason: 'specificReason',
        minimumAmount: 'minimumAmount',
        maximumAmount: 'maximumAmount',
        alarmEmail: 'alarmEmail',
        importanceLevelObject : "importanceLevelObject",
        entity: "entity",
        article : "article",
    }
}