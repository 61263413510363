import React from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    AlertTitle,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Switch,
    TextField,
    Theme,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { CenterFocusStrong, DataArray, DisplaySettings, Edit, EnergySavingsLeafRounded, ExpandMore, Folder, LocalShipping, Mail, ManageAccounts, Map, QrCode2, Save, Settings, SettingsAccessibility, SportsHockeyTwoTone, SupervisedUserCircle } from '@mui/icons-material';
import { UserAccount } from '../../../models/UserAccount';
import { DefaultDialogTitle } from '../../dialogs/DefaultDialogTitle';
import { StringHelper } from '../../../helpers/string-helper';
import { usePortalAccountStyles } from '../../../styles/usePortalAccountStyles';
import { useSnackbar } from 'notistack';
import { UserType } from '../../../models/UserType';
import { Autocomplete } from '@mui/material';
import { ObjectDisplayType, ObjectUnit, QMGradeErrorPointsSettings, QMGradeItemThing, QMRatingType, QMSettings, ServiceWorkerCountCarbonDioxideType } from '../../../models/ObjectUnit';
import { GroupSettingFieldsIntervalControl, IntervalType } from '../../core/intervalControl/GroupSettingFieldsIntervalControl';
import { AppointmentModel } from '@devexpress/dx-react-scheduler';
import { mapGroupSettings } from '../../../helpers/group-settings-management';
import { GroupSettingsIntervalControl, ItemThing, ItemThingType } from '../../../models/GroupSettings';
import { deepCopy } from '../../../helpers/general';
import { UserServiceWorkerSelectionForObjectsDialog } from './UserServiceWorkerSelectionForObjectsDialog';
import { UserServiceWorkerSelectionDialog } from './UserServiceWorkerSelectionDialog';
import { UserServiceWorker } from '../../../models/UserServiceWorker';
import { IntervalContext } from '../PortalPage';
import { LicenseBlack, LicenseBlackSize, LicenseWhite } from '../../../assets';
import { makeValidAppointments } from '../../../helpers/common';
import { GroupSelectionAssignQmControl } from './GroupSelectionAssignQmControl';
import { GroupLicenseTable } from '../../../models/GroupLicenseTable';
import { MaterialsSelectionAssignToObjectDialog } from './MaterialsSelectionAssignToObjectDialog';
import { GroupSelectionAssignToObjectDialog } from './GroupSelectionAssignToObjectDialog';
import { ObjectSharedFolderDashboardDialog } from '../../dialogs/ObjectSharedFolderDashboardDialog';
import { AssignLicenseChoosingTableDialog, ShowCountType } from '../../dialogs/AssignLicenseChoosingTableDialog';
import { GroupLicense } from '../../../models/GroupLicense';
import { api } from '../../../api';
import { ObjectQrCodeDialog } from './ObjectQrCodeDialog';
import { ObjectPropertyDialog } from './property/PropertyObjectDialog';
import { UserProperty } from '../../../models/UserProperty';
import Window from '@mui/icons-material/Window';
import { useTranslation } from 'react-i18next';
import { ObjectManagementPageEditCoyToOtherObjects } from './ObjectManagementPageEditCoyToOtherObjects';


export const ObjectManagementPageEditingDialog = ({ visible, setVisible, object, onSave, objects, setGroupSelectionObjectLeadersDialogVisible, setObjectGroupSettingsDialogVisible, userServiceWorkers, freeLicenses, token, userProperties, groups }
    : { visible: boolean, setVisible: any, object?: ObjectUnit, objects: ObjectUnit[], onSave: any, setGroupSelectionObjectLeadersDialogVisible: any, setObjectGroupSettingsDialogVisible: any, userServiceWorkers: UserServiceWorker[], freeLicenses: GroupLicense[], token: any, userProperties: UserProperty[], groups: any[] }) => {

    const classes = usePortalAccountStyles();
    const theme = useTheme();

    const [objectManagementPageEditCoyToOtherObjectsVisible, setObjectManagementPageEditCoyToOtherObjectsVisible] = React.useState(false);

    const [currentObject, setCurrentObject] = React.useState<ObjectUnit>(new ObjectUnit());

    const [isAssignQmToGroupVisible, setIsAssignQmToGroupVisible] = React.useState(false);
    const [nameError, setNameError] = React.useState('');
    const [typeError, setTypeError] = React.useState('');
    const [unitPriceError, setUnitPriceError] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [myAppointments, setMyAppointments] = React.useState<AppointmentModel[]>([]);
    const [serviceWorkerCountCarbonDioxide, setServiceWorkerCountCarbonDioxide] = React.useState(0);
    const [groupSelectionAssignServiceWorkerToCo2DialogVisible, setGroupSelectionAssignServiceWorkerToCo2DialogVisible] = React.useState(false);
    const [currentWorker, setCurrentWorker] = React.useState<ServiceWorkerCountCarbonDioxideType>();
    const [qrCodeDialogVisible, setQrCodeDialogVisible] = React.useState(false);

    const [assignPropertiesDialogVisible, setAssignPropertiesDialogVisible] = React.useState(false);


    const [groupSelectionAssignServiceWorkerDialogVisible, setGroupSelectionAssignServiceWorkerDialogVisible] = React.useState(false);

    const [currentQmGroups, setCurrentQmGroups] = React.useState<GroupLicenseTable[]>([]);

    const [groupSelectionAssignObjectDialogVisible, setGroupSelectionAssignObjectDialogVisible] = React.useState(false);


    const [myAppointmentsQM, setMyAppointmentsQM] = React.useState<AppointmentModel[]>([]);
    const [isMaterialSelectionAssignToObjectDialogVisible, setIsMaterialSelectionAssignToObjectDialogVisible] = React.useState(false);


    const [isSharedObjectDataVisible, setIsSharedObjectDataVisible] = React.useState(false);
    const [assignStandaloneObjectLicenseDialogVisible, setAssignStandaloneObjectLicenseDialogVisible] = React.useState(false);
    const { t } = useTranslation();

    const [selectedUserProperties, setSelectedUserProperties] = React.useState<UserProperty[]>([]);


    const onChange = (event: any) => {
        let intervalControl = mapGroupSettings({ intervalControl: currentObject.intervalControl }, event.target.name, event.target.value);
        currentObject.intervalControl = intervalControl.intervalControl;
        setCurrentObject({ ...currentObject });
    };

    const onChangeQM = (event: any) => {
        let intervalControl = mapGroupSettings({ intervalControl: currentObject.qmSettings.qmIntervalControl }, event.target.name, event.target.value);
        currentObject.qmSettings.qmIntervalControl = intervalControl.intervalControl;
        setCurrentObject({ ...currentObject });
    };

    const onClose = () => {
        setCurrentObject(ObjectUnit.CreateObject());
        setCurrentQmGroups([]);
        setVisible(false);
    }
    const { enqueueSnackbar } = useSnackbar();

    const isValid = () => {
        let valid = true;

        if (StringHelper.IsNullOrWhiteSpace(currentObject.name)) {
            setNameError("Name ist erforderlich");
            enqueueSnackbar("Name pflicht!", { variant: 'error' });
            valid = false;
        }

        return valid;
    }

    const resetErrorText = () => {
        setNameError('');
        setTypeError('');
        setUnitPriceError('');
    }

    React.useEffect(() => {

        (async () => {
            if (object) {
                if (!object.intervalControl) {
                    object.intervalControl = new GroupSettingsIntervalControl();
                }
                if (object.intervalControl) {
                    if (!object.intervalControl?.calendarEntries) object.intervalControl.calendarEntries = [];
                    setMyAppointments(makeValidAppointments(object.intervalControl?.calendarEntries));
                }

                if (!object.qmSettings) {
                    object.qmSettings = new QMSettings();
                }
                if (object.qmSettings.qmIntervalControl) {
                    if (!object.qmSettings.qmIntervalControl?.calendarEntries) object.qmSettings.qmIntervalControl.calendarEntries = [];
                    setMyAppointmentsQM(makeValidAppointments(object.qmSettings.qmIntervalControl?.calendarEntries));
                }
                setServiceWorkerCountCarbonDioxide(object?.serviceWorkerCountCarbonDioxideTypes?.length || 0);
                setSelectedUserProperties(userProperties.filter(x => object.userPropertyIds.includes(x.id)));
                setCurrentObject(object);
            } else {
                setCurrentObject(ObjectUnit.CreateObject());
            }

            resetErrorText();

        })();

    }, [visible, object]);

    const generateWorkersCarbonDioxide = () => {
        return (
            <Grid item container direction="column" spacing={3}>
                {currentObject.serviceWorkerCountCarbonDioxideTypes.map(worker => {
                    let currentServiceWorker = userServiceWorkers.find(serviceWorker => serviceWorker.id == worker.serviceWorkerId);
                    return (
                        <Grid item container direction={"row"} spacing={3}>
                            <Grid item xs={6} lg={3}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">{t("myObjectSettings.arrivalType")}</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={worker.name}
                                        label={t("myObjectSettings.arrivalType")}
                                        onChange={(event) => {
                                            worker.name = event.target.value;
                                            ServiceWorkerCountCarbonDioxideType.mapPercent(worker);
                                            setCurrentObject({ ...currentObject });
                                        }}
                                    >
                                        <MenuItem value={"Auto"}>Auto</MenuItem>
                                        <MenuItem value={"Öffentlich"}>Öffentlich</MenuItem>
                                        <MenuItem value={"Zu Fuß"}>Zu Fuß</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} lg={3}>
                                <TextField
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    label={t("myObjectSettings.consumptionPercentage")}
                                    disabled
                                    variant="filled"
                                    value={worker.percent + '%'}
                                />
                            </Grid>
                            {currentServiceWorker &&
                                <Grid item xs={6} lg={3}>
                                    <TextField
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                        label="Servicekraft"
                                        disabled
                                        variant="filled"
                                        value={currentServiceWorker.name}
                                    />
                                </Grid>
                            }
                            <Grid item xs={6} lg={3}>
                                <Tooltip title="Servicekraft zur Anreise ">
                                    <IconButton
                                        color="primary"
                                        onClick={async () => {
                                            setGroupSelectionAssignServiceWorkerToCo2DialogVisible(true);
                                            setCurrentWorker(worker);
                                        }}
                                        size="large">
                                        <ManageAccounts />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    )
                })}
            </Grid>
        )


    }



    return (
        <Dialog open={visible} onClose={onClose} fullScreen>
            <DefaultDialogTitle
                title={t("myObjectSettings.editObject")}
                handleClose={onClose}
            />

            <DialogContent>
                <Grid container direction="row" className={classes.container}>
                    <Grid container xs={12} lg={12} direction="row" spacing={3} item>
                        <Grid item xs={12} lg={12}>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header">
                                    <Grid item xs={12} container direction={"row"} spacing={3} alignItems="center">
                                        <Grid item>
                                            {LicenseBlackSize(50, 50)}
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h4">{t("myObjectSettings.licenses")}</Typography>
                                        </Grid>
                                    </Grid>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Button
                                        color="primary"
                                        variant='contained'
                                        endIcon={LicenseWhite()}
                                        onClick={async () => {
                                            setAssignStandaloneObjectLicenseDialogVisible(true);
                                        }}
                                        size="large">
                                        {t("myObjectSettings.assignStandaloneLicense")}
                                    </Button>
                                </AccordionDetails>
                                <AssignLicenseChoosingTableDialog
                                    showCountType={ShowCountType.ObjectStandalone}
                                    visible={assignStandaloneObjectLicenseDialogVisible}
                                    licenses={freeLicenses}
                                    onClose={() => {
                                        setAssignStandaloneObjectLicenseDialogVisible(false);
                                    }}
                                    onLicenseSelected={async (selectedLicense: GroupLicense) => {
                                        if (!currentObject) return;
                                        await api.assignLicenseObjectStandaloneToObject(currentObject.id, selectedLicense.id, token.current, enqueueSnackbar);
                                        setAssignStandaloneObjectLicenseDialogVisible(false);
                                    }}
                                />
                            </Accordion>

                        </Grid>




                        <Grid item xs={12} lg={12}>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header">
                                    <Grid item xs={12} container direction={"row"} spacing={3} alignItems="center">
                                        <Grid item>
                                            <Map fontSize={"large"} />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h4">{t("myObjectSettings.objectLocation")}</Typography>
                                        </Grid>
                                    </Grid>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container direction={"row"} spacing={3}>
                                        <Grid item xs={12}>
                                            <Button endIcon={<QrCode2 />} variant='contained' color='primary' onClick={() => { setQrCodeDialogVisible((val) => !val) }}>
                                                {t("myUnitsSettings.showQrCode")}
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button endIcon={<Window />} variant='contained' color='primary' onClick={() => { setAssignPropertiesDialogVisible((val) => !val) }}>
                                                {t("myObjectSettings.assignFeatures")}
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography component="h4" variant="h4">{t("myObjectSettings.details")}</Typography>
                                        </Grid>

                                        <Grid item xs={12} lg={12}>
                                            <TextField
                                                fullWidth
                                                InputLabelProps={{ shrink: true }}
                                                label="Name"
                                                variant="filled"
                                                value={currentObject.name}
                                                onChange={(event: any) => { setCurrentObject({ ...currentObject, name: event.target.value }) }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} lg={12}>
                                            <TextField
                                                fullWidth
                                                InputLabelProps={{ shrink: true }}
                                                label={t("myObjectSettings.objectNumber")}
                                                variant="filled"
                                                value={currentObject.objectNumber}
                                                onChange={(event: any) => { setCurrentObject({ ...currentObject, objectNumber: event.target.value }) }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} lg={12}>
                                            <TextField
                                                fullWidth
                                                InputLabelProps={{ shrink: true }}
                                                label={t("myUnitsSettings.customerName")}
                                                variant="filled"
                                                value={currentObject.customerName}
                                                onChange={(event: any) => { setCurrentObject({ ...currentObject, customerName: event.target.value }) }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} lg={12}>
                                            <TextField
                                                fullWidth
                                                InputLabelProps={{ shrink: true }}
                                                label={t("myUnitsSettings.address")}
                                                variant="filled"
                                                value={currentObject.addressLine}
                                                onChange={(event: any) => { setCurrentObject({ ...currentObject, addressLine: event.target.value }) }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} lg={12}>
                                            <TextField
                                                fullWidth
                                                id="filled-name"
                                                InputLabelProps={{ shrink: true }}
                                                label={t("myUnitsSettings.city")}
                                                variant="filled"
                                                value={currentObject.city}
                                                onChange={(event: any) => { setCurrentObject({ ...currentObject, city: event.target.value }) }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} lg={4}>
                                            <TextField
                                                fullWidth
                                                InputLabelProps={{ shrink: true }}
                                                label={t("myUnitsSettings.state")}
                                                variant="filled"
                                                value={currentObject.state}
                                                onChange={(event: any) => { setCurrentObject({ ...currentObject, state: event.target.value }) }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} lg={4}>
                                            <TextField
                                                fullWidth
                                                InputLabelProps={{ shrink: true }}
                                                label={t("myUnitsSettings.postalCode")}
                                                variant="filled"
                                                value={currentObject.zipCode}
                                                onChange={(event: any) => { setCurrentObject({ ...currentObject, zipCode: event.target.value }) }}
                                            />
                                        </Grid>

                                        <Grid item xs={12} lg={4}>
                                            <TextField
                                                fullWidth
                                                InputLabelProps={{ shrink: true }}
                                                label={t("myUnitsSettings.country")}
                                                variant="filled"
                                                value={currentObject.country}
                                                onChange={(event: any) => { setCurrentObject({ ...currentObject, country: event.target.value }) }}
                                            />
                                        </Grid>

                                        <Grid item xs={12} lg={6}>
                                            <TextField
                                                fullWidth
                                                InputLabelProps={{ shrink: true }}
                                                label={t("myObjectSettings.description")}
                                                variant="filled"
                                                value={currentObject.description}
                                                onChange={(event: any) => { setCurrentObject({ ...currentObject, description: event.target.value }) }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} lg={6}>
                                            <TextField
                                                fullWidth
                                                InputLabelProps={{ shrink: true }}
                                                label={t("myObjectSettings.costCenter")}
                                                variant="filled"
                                                value={currentObject.costLocation}
                                                onChange={(event: any) => { setCurrentObject({ ...currentObject, costLocation: event.target.value }) }}
                                            />
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                            <ObjectQrCodeDialog
                                visible={qrCodeDialogVisible}
                                setVisible={setQrCodeDialogVisible}
                                object={currentObject}
                                setObject={setCurrentObject}

                            />
                            <ObjectPropertyDialog open={assignPropertiesDialogVisible} setOpen={setAssignPropertiesDialogVisible} objects={objects} properties={userProperties} selectedProperties={selectedUserProperties} setSelectedProperties={setSelectedUserProperties} />
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header">
                                    <Grid item xs={12} container direction={"row"} spacing={3} alignItems="center">
                                        <Grid item>
                                            <Settings fontSize={"large"} />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h4">{t("myObjectSettings.objectSettings")}</Typography>
                                        </Grid>
                                    </Grid>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container direction={"row"} spacing={3}>
                                        <Grid item xs={12}>
                                            <Typography variant='h5'>{t("myObjectSettings.serviceStaffOptions")}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={currentObject.isQrCodeSignInForcedEnabled}
                                                        onChange={(event: any) => { setCurrentObject({ ...currentObject, isQrCodeSignInForcedEnabled: event.target.checked }) }}
                                                        name="isEnabled"
                                                        color="primary"
                                                    />
                                                }
                                                label={(t("myObjectSettings.enforceQRCodeForObjectCheckIn"))}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={currentObject.isQrCodeSignOutForcedEnabled}
                                                        onChange={(event: any) => { setCurrentObject({ ...currentObject, isQrCodeSignOutForcedEnabled: event.target.checked }) }}
                                                        name="isEnabled"
                                                        color="primary"
                                                    />
                                                }
                                                label={(t("myObjectSettings.enforceQRCodeForObjectCheckOut"))}
                                            />
                                        </Grid>


                                        <Grid item xs={12}>
                                            <Typography variant='h5'>{t("myObjectSettings.objectLeaderOptions")}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={currentObject.isQrCodeGroupSignInObjectLeaderForcedEnabled}
                                                        onChange={(event: any) => { setCurrentObject({ ...currentObject, isQrCodeGroupSignInObjectLeaderForcedEnabled: event.target.checked }) }}
                                                        name="isEnabled"
                                                        color="primary"
                                                    />
                                                }
                                                label={t("myObjectSettings.enforceQRCodeForUnitCheckIn")}
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={6} lg={3}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                endIcon={<SupervisedUserCircle />}
                                                disabled={loading}
                                                onClick={async () => {
                                                    setGroupSelectionObjectLeadersDialogVisible(true);
                                                }}
                                            >
                                                {t("myUnitsSettings.showCurrentObjectManagers")}
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant='h5'>{t("myObjectSettings.groupSettingsForAllUnitsInObject")}</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={3}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                endIcon={<Edit />}
                                                disabled={loading}
                                                onClick={async () => {
                                                    setObjectGroupSettingsDialogVisible(true);
                                                }}
                                            >
                                                {t("myObjectSettings.editGroupSettings")}
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControl component="fieldset">
                                                <FormLabel component="legend">{t("myObjectSettings.objectDisplay")}</FormLabel>
                                                <RadioGroup
                                                    row
                                                    name="displayLastCleaningType"
                                                    value={currentObject.objectDisplayType}
                                                    onChange={(event) => {
                                                        setCurrentObject({ ...currentObject, objectDisplayType: parseInt(event.target.value) as any })
                                                    }}
                                                >
                                                    <FormControlLabel
                                                        value={ObjectDisplayType.Standard}
                                                        control={<Radio />}
                                                        label={t("myObjectSettings.nameAndAddress")}
                                                    />
                                                    <FormControlLabel
                                                        value={ObjectDisplayType.DescriptionCostLocation}
                                                        control={<Radio />}
                                                        label={t("myObjectSettings.descriptionAndCostCenter")}
                                                    />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>



                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        {currentObject.qmSettings &&
                            <Grid item xs={12} lg={12}>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMore />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header">
                                        <Grid item xs={12} container direction={"row"} spacing={3} alignItems="center">
                                            <Grid item>
                                                <CenterFocusStrong fontSize={"large"} />
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="h4">{t("myObjectSettings.qualityManagementPlanning")}</Typography>
                                            </Grid>
                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container direction={"row"} spacing={3}>
                                            <Grid item>
                                                <Button variant='contained' color='primary' onClick={() => { setIsAssignQmToGroupVisible((val) => !val) }}>
                                                    {t("myObjectSettings.activateQMOnUnits")}
                                                </Button>
                                            </Grid>
                                            <Grid item lg={6}>
                                                {currentQmGroups.length > 0 && <Typography style={{ fontSize: 32, fontWeight: 'bold' }}>{t("myObjectSettings.QMOnUnitsActivated")}: {currentQmGroups.length}</Typography>}
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormControl component="fieldset">
                                                    <FormLabel component="legend">{t("myObjectSettings.assessmentType")}</FormLabel>
                                                    <RadioGroup
                                                        row
                                                        name="qmRatingType"
                                                        value={currentObject.qmSettings.qmRatingType}
                                                        onChange={(event) => {
                                                            setCurrentObject({ ...currentObject, qmSettings: { ...currentObject.qmSettings, qmRatingType: parseInt(event.target.value) as any } })
                                                        }}
                                                    >
                                                        <FormControlLabel
                                                            value={QMRatingType.QMStandard}
                                                            control={<Radio />}
                                                            label={t("myObjectSettings.standardServices")}
                                                        />
                                                        <FormControlLabel
                                                            value={QMRatingType.QMWeighting}
                                                            control={<Radio />}
                                                            label={t("myObjectSettings.servicesWithWeighting")}
                                                        />
                                                        <FormControlLabel
                                                            value={QMRatingType.QMErrorPointsCount}
                                                            control={<Radio />}
                                                            label={t("myObjectSettings.assessmentByNumberOfErrorPoints")}
                                                        />
                                                    </RadioGroup>
                                                </FormControl>
                                            </Grid>
                                            {currentObject.qmSettings.qmRatingType == QMRatingType.QMErrorPointsCount &&
                                                <Grid item xs={12} container direction="column" spacing={3}>
                                                    <Grid item >
                                                        <Alert severity="info">{t("myObjectSettings.niveaus")}</Alert>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button variant='contained' color='primary' onClick={() => {
                                                            let item1 = new QMGradeItemThing(ItemThingType.MainUsageComponent, 1, 2, 3, 4, 5);
                                                            let item2 = new QMGradeItemThing(ItemThingType.RestInventory, 1, 2, 3, 4, 5);
                                                            let item4 = new QMGradeItemThing(ItemThingType['Walls&Covers'], 1, 2, 3, 4, 5);
                                                            let item3 = new QMGradeItemThing(ItemThingType.Floor, 1, 2, 3, 4, 5);
                                                            let item5 = new QMGradeItemThing(ItemThingType.HardToSeeThings, 1, 2, 3, 4, 5);


                                                            let newItem = new QMGradeErrorPointsSettings(0, 0, [item1, item2, item3, item4, item5]);
                                                            newItem.minAreaSize = 0;
                                                            newItem.maxAreaSize = 0;

                                                            if (!currentObject.qmSettings.qmGradeErrorPointsSettings) currentObject.qmSettings.qmGradeErrorPointsSettings = [];

                                                            currentObject.qmSettings.qmGradeErrorPointsSettings.push(newItem);
                                                            setCurrentObject({ ...currentObject })

                                                        }}>
                                                            {t("myObjectSettings.addAssessmentTable")}
                                                        </Button>
                                                    </Grid>

                                                    {currentObject?.qmSettings?.qmGradeErrorPointsSettings?.map(item => {
                                                        return (
                                                            <Grid item container direction={"row"} spacing={3}>
                                                                <Grid item>
                                                                    <TextField
                                                                        style={{ width: 200 }}
                                                                        required
                                                                        variant="outlined"
                                                                        margin="normal"
                                                                        label={t("myObjectSettings.roomSizeFrom")}
                                                                        type="number"
                                                                        disabled={loading}
                                                                        InputProps={{
                                                                            endAdornment: <InputAdornment position="end">m²</InputAdornment>
                                                                        }}
                                                                        value={item.minAreaSize}
                                                                        onChange={(event: any) => {
                                                                            item.minAreaSize = event.target.value;
                                                                            setCurrentObject({ ...currentObject });
                                                                        }}

                                                                    />
                                                                </Grid>
                                                                <Grid item>
                                                                    <TextField
                                                                        style={{ width: 200 }}
                                                                        required
                                                                        variant="outlined"
                                                                        margin="normal"
                                                                        label={t("myObjectSettings.roomSizeTo")}
                                                                        type="number"
                                                                        disabled={loading}
                                                                        InputProps={{
                                                                            endAdornment: <InputAdornment position="end">m²</InputAdornment>
                                                                        }}
                                                                        value={item.maxAreaSize}
                                                                        onChange={(event: any) => {
                                                                            item.maxAreaSize = event.target.value;
                                                                            setCurrentObject({ ...currentObject });
                                                                        }}

                                                                    />
                                                                </Grid>
                                                                <Grid item container direction={"column"}>
                                                                    {item?.qmGradeItemThing?.map(qmGradeItemThing => {
                                                                        return (
                                                                            <Grid container item direction={"row"} alignItems={"center"} spacing={2}>
                                                                                <Grid item lg={2}>
                                                                                    <Typography fontWeight={'bold'} fontSize={26}>{ItemThing.getItemThingTypeText(qmGradeItemThing.type, t)}</Typography>
                                                                                </Grid>
                                                                                <Grid item>
                                                                                    <TextField
                                                                                        style={{ width: 200 }}
                                                                                        required
                                                                                        variant="outlined"
                                                                                        margin="normal"
                                                                                        label="Niveau 1"
                                                                                        type="number"
                                                                                        disabled={loading}
                                                                                        InputProps={{
                                                                                            endAdornment: <InputAdornment position="end">{t("myObjectSettings.numberOfErrors")}</InputAdornment>
                                                                                        }}
                                                                                        value={qmGradeItemThing.grade1}
                                                                                        onChange={(event: any) => {
                                                                                            qmGradeItemThing.grade1 = event.target.value;
                                                                                            setCurrentObject({ ...currentObject });
                                                                                        }}

                                                                                    />
                                                                                </Grid>
                                                                                <Grid item>
                                                                                    <TextField
                                                                                        style={{ width: 200 }}
                                                                                        required
                                                                                        variant="outlined"
                                                                                        margin="normal"
                                                                                        label="Niveau 2"
                                                                                        type="number"
                                                                                        disabled={loading}
                                                                                        InputProps={{
                                                                                            endAdornment: <InputAdornment position="end">{t("myObjectSettings.numberOfErrors")}</InputAdornment>
                                                                                        }}
                                                                                        value={qmGradeItemThing.grade2}
                                                                                        onChange={(event: any) => {
                                                                                            qmGradeItemThing.grade2 = event.target.value;
                                                                                            setCurrentObject({ ...currentObject });
                                                                                        }}

                                                                                    />
                                                                                </Grid>
                                                                                <Grid item>
                                                                                    <TextField
                                                                                        style={{ width: 200 }}
                                                                                        required
                                                                                        variant="outlined"
                                                                                        margin="normal"
                                                                                        label="Niveau 3"
                                                                                        type="number"
                                                                                        disabled={loading}
                                                                                        InputProps={{
                                                                                            endAdornment: <InputAdornment position="end">{t("myObjectSettings.numberOfErrors")}</InputAdornment>
                                                                                        }}
                                                                                        value={qmGradeItemThing.grade3}
                                                                                        onChange={(event: any) => {
                                                                                            qmGradeItemThing.grade3 = event.target.value;
                                                                                            setCurrentObject({ ...currentObject });
                                                                                        }}

                                                                                    />
                                                                                </Grid>
                                                                                <Grid item>
                                                                                    <TextField
                                                                                        style={{ width: 200 }}
                                                                                        required
                                                                                        variant="outlined"
                                                                                        margin="normal"
                                                                                        label="Niveau 4"
                                                                                        type="number"
                                                                                        disabled={loading}
                                                                                        InputProps={{
                                                                                            endAdornment: <InputAdornment position="end">{t("myObjectSettings.numberOfErrors")}</InputAdornment>
                                                                                        }}
                                                                                        value={qmGradeItemThing.grade4}
                                                                                        onChange={(event: any) => {
                                                                                            qmGradeItemThing.grade4 = event.target.value;
                                                                                            setCurrentObject({ ...currentObject });
                                                                                        }}

                                                                                    />
                                                                                </Grid>
                                                                                <Grid item>
                                                                                    <TextField
                                                                                        style={{ width: 200 }}
                                                                                        required
                                                                                        variant="outlined"
                                                                                        margin="normal"
                                                                                        label="Niveau 5"
                                                                                        type="number"
                                                                                        disabled={loading}
                                                                                        InputProps={{
                                                                                            endAdornment: <InputAdornment position="end">{t("myObjectSettings.numberOfErrors")}</InputAdornment>
                                                                                        }}
                                                                                        value={qmGradeItemThing.grade5}
                                                                                        onChange={(event: any) => {
                                                                                            qmGradeItemThing.grade5 = event.target.value;
                                                                                            setCurrentObject({ ...currentObject });
                                                                                        }}

                                                                                    />
                                                                                </Grid>
                                                                            </Grid>
                                                                        )
                                                                    })}
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Divider />
                                                                </Grid>
                                                            </Grid>
                                                        )

                                                    })}
                                                </Grid>
                                            }
                                            <Grid item lg={3}>
                                                <TextField
                                                    style={{ width: 200 }}
                                                    required
                                                    variant="outlined"
                                                    margin="normal"
                                                    label={t("myObjectSettings.batchSize")}
                                                    type="number"
                                                    disabled={loading}
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end">%</InputAdornment>
                                                    }}
                                                    value={currentObject.qmSettings.qmPotSize}
                                                    onChange={(event: any) => { setCurrentObject({ ...currentObject, qmSettings: { ...currentObject.qmSettings, qmPotSize: event.target.value } }) }}

                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <IntervalContext.Provider value={{
                                                    intervalType: IntervalType.QMObject,
                                                    refreshSettings: () => {
                                                        let res = { target: { name: 'row', value: null } };
                                                        onChangeQM(res)
                                                    }
                                                }}>
                                                    <GroupSettingFieldsIntervalControl
                                                        services={[]}
                                                        intervalControl={currentObject.qmSettings.qmIntervalControl}
                                                        onChange={onChangeQM}
                                                        setMyAppointments={setMyAppointmentsQM}
                                                        myAppointments={myAppointmentsQM}
                                                        labelTime={"Reaktionszeit"}
                                                        title={t("QM - Auslösung - Planung")}
                                                    />
                                                </IntervalContext.Provider>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                                <GroupSelectionAssignQmControl open={isAssignQmToGroupVisible} setOpen={setIsAssignQmToGroupVisible} objectUnit={currentObject} onRefresh={(groups: GroupLicenseTable[]) => {
                                    setCurrentQmGroups(groups);
                                }} />

                            </Grid>
                        }
                        {ObjectUnit.IsStandaloneLicenseValid(currentObject) ?
                            <React.Fragment>

                                <Grid item xs={12}>
                                    <IntervalContext.Provider value={{
                                        intervalType: IntervalType.Object,
                                        refreshSettings: () => {
                                            let res = { target: { name: 'row', value: null } };
                                            onChange(res)
                                        }
                                    }}>
                                        <GroupSettingFieldsIntervalControl
                                            services={[]}
                                            intervalControl={currentObject.intervalControl}
                                            onChange={onChange}
                                            setMyAppointments={setMyAppointments}
                                            myAppointments={myAppointments}
                                            labelTime={t("object-sessions.attendanceDuration")}
                                            title={t("Anwesenheitsplanung")}
                                        />
                                    </IntervalContext.Provider>
                                </Grid>
                            </React.Fragment> :
                            <Grid item xs={12}>
                                <Alert severity="error">
                                    <AlertTitle>Objektleiter Benachrichtigung</AlertTitle>
                                    Dieses Feature ist deaktiviert, solange Sie sich keine Standalone Objekt Lizenz gekauft haben!
                                    <Button
                                        color="primary"
                                        onClick={onClose}
                                    >
                                        Schliessen und Lizenz kaufen!
                                    </Button>
                                </Alert>
                            </Grid>
                        }
                        <Grid item xs={12} lg={12}>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header">
                                    <Grid item xs={12} container direction={"row"} spacing={3} alignItems="center">
                                        <Grid item>
                                            <ManageAccounts fontSize={"large"} />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h4">{t("myObjectSettings.serviceStaffAssignment")}</Typography>
                                        </Grid>
                                    </Grid>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid item>
                                        <Button
                                            color="primary"
                                            variant='contained'
                                            endIcon={<ManageAccounts />}
                                            onClick={async () => {
                                                setGroupSelectionAssignServiceWorkerDialogVisible(true);
                                            }}
                                            size="large">
                                            {t("myObjectSettings.assignServiceStaffToObject")}
                                        </Button>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                            <UserServiceWorkerSelectionForObjectsDialog onRefresh={() => { }} open={groupSelectionAssignServiceWorkerDialogVisible} setOpen={setGroupSelectionAssignServiceWorkerDialogVisible} currentObject={currentObject} objects={objects} />

                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header">
                                    <Grid item xs={12} container direction={"row"} spacing={3} alignItems="center">
                                        <Grid item>
                                            <Mail fontSize={"large"} />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h4">{t("myObjectSettings.notifications")}</Typography>
                                        </Grid>
                                    </Grid>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container direction={"column"} spacing={3}>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                style={{ width: 400 }}
                                                name={"alarmEmailMaterialStock"}
                                                InputLabelProps={{ shrink: true }}
                                                label={t("myObjectSettings.materialManagementAlarmEmail")}
                                                variant="filled"
                                                value={currentObject?.notificationSettings?.alarmEmailMaterialStock}
                                                onChange={(event: any) => { setCurrentObject({ ...currentObject, notificationSettings: { ...currentObject.notificationSettings, alarmEmailMaterialStock: event.target.value } }) }}
                                            />
                                        </Grid>
                                        {ObjectUnit.IsStandaloneLicenseValid(currentObject) ?
                                            <Grid item container direction={"column"} spacing={3}>
                                                <Grid container direction={"row"} spacing={3} item>
                                                    <Grid item>
                                                        <TextField
                                                            fullWidth
                                                            style={{ width: 400 }}
                                                            InputLabelProps={{ shrink: true }}
                                                            label={t("myObjectSettings.employeeNoShowEmail")}
                                                            variant="filled"
                                                            value={currentObject?.notificationSettings?.serviceWorkerAbscenceEmail}
                                                            onChange={(event: any) => { setCurrentObject({ ...currentObject, notificationSettings: { ...currentObject.notificationSettings, serviceWorkerAbscenceEmail: event.target.value } }) }}
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={currentObject?.notificationSettings?.serviceWorkerAbscenceNotificationEnabled}
                                                                    onChange={(event: any) => { setCurrentObject({ ...currentObject, notificationSettings: { ...currentObject.notificationSettings, serviceWorkerAbscenceNotificationEnabled: event.target.checked } }) }}
                                                                    name="isEnabled"
                                                                    color="primary"
                                                                />
                                                            }
                                                            label={t("myObjectSettings.totalFailureNotificationForObjectManagerActivated")}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid container direction={"row"} spacing={3} item>
                                                    <Grid item>
                                                        <TextField
                                                            fullWidth
                                                            style={{ width: 400 }}
                                                            InputLabelProps={{ shrink: true }}
                                                            label={t("myObjectSettings.employeeCountBelowThresholdEmail")}
                                                            variant="filled"
                                                            value={currentObject?.notificationSettings?.presenceTooLowEmail}
                                                            onChange={(event: any) => { setCurrentObject({ ...currentObject, notificationSettings: { ...currentObject.notificationSettings, presenceTooLowEmail: event.target.value } }) }}
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={currentObject?.notificationSettings?.presenceTooLowEmailNotificationEnabled}
                                                                    onChange={(event: any) => { setCurrentObject({ ...currentObject, notificationSettings: { ...currentObject.notificationSettings, presenceTooLowEmailNotificationEnabled: event.target.checked } }) }}
                                                                    name="isEnabled"
                                                                    color="primary"
                                                                />
                                                            }
                                                            label={t("myObjectSettings.employeeCountBelowThresholdEmailForObjectManagerActivated")}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid container direction={"row"} spacing={3} item>
                                                    <Grid item>
                                                        <TextField
                                                            fullWidth
                                                            style={{ width: 400 }}
                                                            InputLabelProps={{ shrink: true }}
                                                            label={t("myObjectSettings.presenceDurationBelowThresholdEmail")}
                                                            variant="filled"
                                                            value={currentObject?.notificationSettings?.presenceDurationTooLowEmail}
                                                            onChange={(event: any) => { setCurrentObject({ ...currentObject, notificationSettings: { ...currentObject.notificationSettings, presenceDurationTooLowEmail: event.target.value } }) }}
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={currentObject?.notificationSettings?.presenceDurationTooLowNotificationEnabled}
                                                                    onChange={(event: any) => { setCurrentObject({ ...currentObject, notificationSettings: { ...currentObject.notificationSettings, presenceDurationTooLowNotificationEnabled: event.target.checked } }) }}
                                                                    name="isEnabled"
                                                                    color="primary"
                                                                />
                                                            }
                                                            label={t("myObjectSettings.presenceDurationBelowThresholdEmailForObjectManagerActivated")}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid container direction={"row"} spacing={3} item>
                                                    <Grid item>
                                                        <TextField
                                                            fullWidth
                                                            style={{ width: 400 }}
                                                            InputLabelProps={{ shrink: true }}
                                                            label={t("myObjectSettings.dailyReportEmail")}
                                                            variant="filled"
                                                            value={currentObject?.notificationSettings?.dailyReportServiceWorkerPresenceEmail}
                                                            onChange={(event: any) => { setCurrentObject({ ...currentObject, notificationSettings: { ...currentObject.notificationSettings, dailyReportServiceWorkerPresenceEmail: event.target.value } }) }}
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={currentObject?.notificationSettings?.dailyReportServiceWorkerPresenceNotificationEnabled}
                                                                    onChange={(event: any) => { setCurrentObject({ ...currentObject, notificationSettings: { ...currentObject.notificationSettings, dailyReportServiceWorkerPresenceNotificationEnabled: event.target.checked } }) }}
                                                                    name="isEnabled"
                                                                    color="primary"
                                                                />
                                                            }
                                                            label={t("myObjectSettings.dailyReportForObjectManagerActivated")}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={currentObject.isDailyReportEnabled}
                                                                onChange={(event: any) => { setCurrentObject({ ...currentObject, isDailyReportEnabled: event.target.checked }) }}
                                                                name="isEnabled"
                                                                color="primary"
                                                            />
                                                        }
                                                        label={t("myObjectSettings.dailyReportCheckServiceStaffLoggedInTodayActivated")}
                                                    />
                                                </Grid>

                                            </Grid> :
                                            <Grid item xs={12}>
                                                <Alert severity="error">
                                                    <AlertTitle>{t("myObjectSettings.objectLeaderDailyReports")}</AlertTitle>
                                                    {t("myObjectSettings.thisFeatureIsDeactivatedAsLongAsYouHaveNoStandalone")}
                                                    <Button
                                                        color="primary"
                                                        onClick={onClose}
                                                    >
                                                        {t("myObjectSettings.closeAndBuyLicense")}!
                                                    </Button>
                                                </Alert>
                                            </Grid>
                                        }
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>

                        </Grid>

                        <Grid item xs={12} lg={12}>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header">
                                    <Grid item xs={12} container direction={"row"} spacing={3} alignItems="center">
                                        <Grid item>
                                            <LocalShipping fontSize={"large"} />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h4">{t("myObjectSettings.materialManagement")}</Typography>
                                        </Grid>
                                    </Grid>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container direction={"column"} spacing={3}>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                style={{ width: 400 }}
                                                name={"alarmEmailMaterialStock"}
                                                InputLabelProps={{ shrink: true }}
                                                label={t("myObjectSettings.materialManagementAlarmEmail")}
                                                variant="filled"
                                                value={currentObject?.notificationSettings?.alarmEmailMaterialStock}
                                                onChange={(event: any) => { setCurrentObject({ ...currentObject, notificationSettings: { ...currentObject.notificationSettings, alarmEmailMaterialStock: event.target.value } }) }}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                color="primary"
                                                variant='contained'
                                                endIcon={<LocalShipping />}
                                                onClick={async () => {
                                                    setIsMaterialSelectionAssignToObjectDialogVisible(true);
                                                }}
                                                size="large">
                                                {t("myObjectSettings.assignMaterialToObject")}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                                {currentObject &&
                                    <MaterialsSelectionAssignToObjectDialog open={isMaterialSelectionAssignToObjectDialogVisible} setOpen={setIsMaterialSelectionAssignToObjectDialogVisible}
                                        objectUnit={currentObject} onRefresh={() => { }} />
                                }
                            </Accordion>

                        </Grid>

                        <Grid item xs={12} lg={12}>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header">
                                    <Grid item xs={12} container direction={"row"} spacing={3} alignItems="center">
                                        <Grid item>
                                            <EnergySavingsLeafRounded fontSize={"large"} />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h4">{t("myObjectSettings.co2Documentation")}</Typography>
                                        </Grid>
                                    </Grid>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <TextField
                                        style={{ marginBottom: 15 }}
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                        label={t("myObjectSettings.numberOfEmployeesCO2")}
                                        variant="filled"
                                        type='number'
                                        value={serviceWorkerCountCarbonDioxide}
                                        onChange={(event: any) => {
                                            setServiceWorkerCountCarbonDioxide(event.target.value);
                                            if (currentObject.serviceWorkerCountCarbonDioxideTypes.length > event.target.value) {
                                                currentObject.serviceWorkerCountCarbonDioxideTypes.splice(-(currentObject.serviceWorkerCountCarbonDioxideTypes.length - event.target.value))
                                            }
                                            let oldVals = deepCopy(currentObject.serviceWorkerCountCarbonDioxideTypes);
                                            currentObject.serviceWorkerCountCarbonDioxideTypes = [];
                                            for (let i = 0; i < event.target.value; i++) {
                                                let newWorker = new ServiceWorkerCountCarbonDioxideType();
                                                newWorker.name = "Auto"
                                                newWorker.percent = 100;
                                                currentObject.serviceWorkerCountCarbonDioxideTypes.push(newWorker);
                                            }



                                            for (let i = 0; i < oldVals.length; i++) {
                                                currentObject.serviceWorkerCountCarbonDioxideTypes[i] = oldVals[i];
                                            }


                                            setCurrentObject({ ...currentObject });
                                        }}
                                    />
                                    {generateWorkersCarbonDioxide()}
                                </AccordionDetails>
                            </Accordion>

                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header">
                                    <Grid item xs={12} container direction={"row"} spacing={3} alignItems="center">
                                        <Grid item>
                                            <Folder fontSize={"large"} />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h4">{t("shared-folders-objects-all.objectFolder")}</Typography>
                                        </Grid>
                                    </Grid>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Button
                                        color="primary"
                                        variant='contained'
                                        endIcon={<Folder />}
                                        onClick={async () => {
                                            setIsSharedObjectDataVisible(true);
                                        }}
                                        size="large">
                                        {t("myObjectSettings.manageObjectFolder")}
                                    </Button>
                                </AccordionDetails>
                                <ObjectSharedFolderDashboardDialog visible={isSharedObjectDataVisible} setVisible={setIsSharedObjectDataVisible} />
                            </Accordion>

                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header">
                                    <Grid item xs={12} container direction={"row"} spacing={3} alignItems="center">
                                        <Grid item>
                                            <DisplaySettings fontSize={"large"} />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h4">{t("myUnitsSettings.assignedUnits")}</Typography>
                                        </Grid>
                                    </Grid>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid item>
                                        <Button
                                            color="primary"
                                            variant='contained'
                                            endIcon={<DisplaySettings />}
                                            onClick={async () => {
                                                setGroupSelectionAssignObjectDialogVisible(true);
                                            }}
                                            size="large">
                                            {t("myObjectSettings.assignUnitToObject")}
                                        </Button>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>

                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={currentObject.isActivatedEnabled}
                                        onChange={(event: any) => { setCurrentObject({ ...currentObject, isActivatedEnabled: event.target.checked }) }}
                                        name="isEnabled"
                                        color="primary"
                                    />
                                }
                                label={t("myObjectSettings.objectActivated")}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Divider />
                        </Grid>

                    </Grid>
                    <GroupSelectionAssignToObjectDialog onRefresh={() => { }} open={groupSelectionAssignObjectDialogVisible} setOpen={setGroupSelectionAssignObjectDialogVisible} objectUnit={currentObject} objects={objects} />
                </Grid>
                {currentObject &&
                    <UserServiceWorkerSelectionDialog onRefresh={(userServiceWorkerId: string) => {
                        if (currentWorker) {
                            currentWorker.serviceWorkerId = userServiceWorkerId;
                            setCurrentObject({ ...currentObject });
                        }
                    }}
                        open={groupSelectionAssignServiceWorkerToCo2DialogVisible} setOpen={setGroupSelectionAssignServiceWorkerToCo2DialogVisible} currentObject={currentObject} objects={objects} serviceWorkerTypeToShow={[]} />
                }
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    variant="contained"
                    style={{ marginRight: 'auto' }}
                    onClick={() => {
                        setObjectManagementPageEditCoyToOtherObjectsVisible(true);
                    }}
                >
                    Settings kopieren auf andere Objekte
                </Button>
                <Button
                    color="primary"
                    onClick={onClose}
                >
                    {t("myUnitsSettings.cancel")}
                </Button>

                <Button
                    variant="contained"
                    color="primary"
                    endIcon={<Save />}
                    disabled={loading}
                    onClick={async () => {
                        if (!isValid()) return;

                        setLoading(true);
                        currentObject.userPropertyIds = selectedUserProperties.map(x => x.id);
                        await onSave(currentObject);
                        setLoading(false);

                        onClose();
                    }}
                >
                    {t("myUnitsSettings.save")}
                </Button>
            </DialogActions>
            <ObjectManagementPageEditCoyToOtherObjects currentObject={currentObject} visible={objectManagementPageEditCoyToOtherObjectsVisible} setVisible={setObjectManagementPageEditCoyToOtherObjectsVisible} token={token} objects={objects} groups={groups} />
        </Dialog >
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            marginTop: theme.spacing(1),
        },
    }),
);
