import React, { useContext } from 'react';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Switch,
    TextField,
    Theme,
    Typography,
    useTheme,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { FileDownload, Save, Shop } from '@mui/icons-material';
import { UserAccount } from '../../../models/UserAccount';
import { DefaultDialogTitle } from '../../dialogs/DefaultDialogTitle';
import { StringHelper } from '../../../helpers/string-helper';
import { usePortalAccountStyles } from '../../../styles/usePortalAccountStyles';
import { useSnackbar } from 'notistack';
import { UserType } from '../../../models/UserType';
import { Autocomplete } from '@mui/material';
import { ObjectUnit } from '../../../models/ObjectUnit';
import { BuildQrCodeLogo } from '../../common/qrcode/qrcodeManagement';
import { api } from '../../../api';
import { getApiToken } from '../../../helpers/storage-management';
import { useHistory } from 'react-router-dom';
import { DigitalCheckpointDialog } from '../../dialogs/groupSettings/DigitalCheckpointDialog';
import { GroupSettings } from '../../../models/GroupSettings';
import digiCheckpoint from '../../../assets/image/shop/digicheckpoint.png';
import { NavigatedFromType } from '../../../models/NavigatedFrom';
import { GlobalContext } from '../PortalPage';
import useNoInitialEffect from '../../../helpers/extensions';
import { useTranslation } from 'react-i18next';

export enum QrCodeObjectType {
    Address = 0,
    Description = 1,
}

export const ObjectQrCodeDialog = ({ visible, setVisible, object, setObject }
    : { visible: boolean, setVisible: any, object?: ObjectUnit, setObject: any }) => {

    const classes = usePortalAccountStyles();
    const theme = useTheme();

    const [currentObject, setCurrentObject] = React.useState<ObjectUnit>(new ObjectUnit());

    const [qrCode, setQrCode] = React.useState();
    const { enqueueSnackbar } = useSnackbar();

    const [nameError, setNameError] = React.useState('');
    const [typeError, setTypeError] = React.useState('');
    const [unitPriceError, setUnitPriceError] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [groupSettings, setGroupSettings] = React.useState<GroupSettings>();
    const history = useHistory();
    const [isDigitalCheckpointDialogVisible, setIsDigitalCheckpointDialogVisible] = React.useState(false);
    const [objectField1, setObjectField1] = React.useState("");
    const [objectField2, setObjectField2] = React.useState("");
    const [qrCodeObjectType, setQrCodeObjectType] = React.useState(QrCodeObjectType.Address);
    const {t} = useTranslation();
    const { sourceLogoUser
    }: any = useContext(GlobalContext);
    const onClose = () => {
        setCurrentObject(ObjectUnit.CreateObject());
        setVisible(false);
    }

    const resetErrorText = () => {
        setNameError('');
        setTypeError('');
        setUnitPriceError('');
    }

    const setQrCodeImage = async () => {
        const tokenTemp = getApiToken(history);
        if (object?.qrCodeInformation.url){
            setQrCode(await api.downloadQrCodeObjectImageWithUrlAsync(tokenTemp, object.id, object.qrCodeInformation.url));
        }else if (object){
            setQrCode(await api.downloadQrCodeObjectImageAsync(tokenTemp, object.id));
        }
    }

    useNoInitialEffect(() => {

        (async () => {

            if (object) {
                setCurrentObject(object);
                let settings = new GroupSettings(object.id);
                if (settings.locationInformation) {
                    settings.locationInformation.notificationName = object.name;
                    settings.locationInformation.roomName = object.customerName;
                    settings.locationInformation.levelName = object.city;
                    settings.locationInformation.objectName = object.addressLine;

                }

                setGroupSettings(settings);
            } else {
                setCurrentObject(ObjectUnit.CreateObject());
            }

            resetErrorText();
            mapQrCodeTypeToText(QrCodeObjectType.Address);

        })();

        (async () => {


            if (object) {
                await setQrCodeImage();
            }

        })();

    }, [visible, object]);



    const mapQrCodeTypeToText = (qrCodeObjectType: QrCodeObjectType) => {
        switch (qrCodeObjectType) {
            case QrCodeObjectType.Address:
                setObjectField1(object?.name || "");
                setObjectField2(`${object?.addressLine} ${object?.city}`);
                break;
            default:
                setObjectField1(object?.description || "");
                setObjectField2(`${object?.costLocation} ${object?.objectNumber}`);
                break
        }
    }


    return (
        <Dialog open={visible} onClose={onClose} fullScreen>
            <DefaultDialogTitle
                title={t("myObjectSettings.objectQRCode")}
                handleClose={onClose}
            />

            <DialogContent>

                <Grid container direction="row" className={classes.container} spacing={3}>
                    <Grid item xs={12}>
                        <RadioGroup
                            row
                            aria-label="gender"
                            name="qrCodeObjectType"
                            value={qrCodeObjectType}
                            onChange={(event) => {
                                mapQrCodeTypeToText(parseInt(event.target.value) as any)
                                setQrCodeObjectType(parseInt(event.target.value));
                            }}
                        >
                            <FormControlLabel
                                value={QrCodeObjectType.Address}
                                control={<Radio />}
                                label={t("myUnitsSettings.address")}
                            />
                            <FormControlLabel
                                value={QrCodeObjectType.Description}
                                control={<Radio />}
                                label={t("myObjectSettings.description")}
                            />
                        </RadioGroup>
                    </Grid>
                    <Grid item>
                        {object &&
                            <BuildQrCodeLogo setQrCodeImage={setQrCodeImage} qrCode={qrCode} firstTitle={objectField1} secondTitle={objectField2} defaultLogo={sourceLogoUser} objectUnit={object} setObject={setObject} />
                        }
                    </Grid>
                    <Grid item xs={12} container direction={"column"} spacing={2}>
                        <Grid item>
                            <Divider />
                        </Grid>
                        <Grid item>
                            <Typography variant="h4" fontWeight={"bold"}>{t("myObjectSettings.digitalObject")}</Typography>
                        </Grid>
                        <Grid item container direction="row" spacing={3} alignItems="center">
                            <Grid item>
                                <Button size="large" variant="contained" startIcon={<FileDownload />} color="primary" onClick={() => {
                                    setIsDigitalCheckpointDialogVisible(true);
                                }}>
                                    {t("myObjectSettings.activateDigitalObject")}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    size="large"
                                    startIcon={<Shop />}
                                    variant={'contained'}
                                    color="primary"

                                    onClick={() => {
                                        const queryParams = { showall: "1", chosenUnit: "", chosenUnitName: "", navigatedFrom: NavigatedFromType.BssdDigitalCheckpoint }
                                        const queryString = new URLSearchParams(queryParams).toString()
                                        history.push({
                                            pathname: '/portal/shop',
                                            search: queryString
                                        });
                                    }}
                                >
                                    {t("myObjectSettings.purchase1DigitalObject")}
                                </Button>
                            </Grid>
                            <Grid item xs={3}>
                                <img src={digiCheckpoint} style={{ width: '100%', objectFit: 'contain' }} />
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Divider />
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    onClick={onClose}
                >
                    {t("myUnitsSettings.close")}
                </Button>
               {/* <Button
                    variant="contained"
                    color="primary"
                    endIcon={<Save />}
                    disabled={loading}
                    onClick={async () => {
                        const tempToken = getApiToken(history);

                        setLoading(true);
                        const added = await api.createUserObjectUnit(object, tempToken, enqueueSnackbar);
                        setLoading(false);

                        onClose();
                    }}
                >
                    Speichern
                </Button> */}
            </DialogActions>
            {object &&
                <DigitalCheckpointDialog open={isDigitalCheckpointDialogVisible} setOpen={setIsDigitalCheckpointDialogVisible} onRefresh={() => { }} groupSettings={undefined} objectUnit={object} />
            }
        </Dialog >
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            marginTop: theme.spacing(1),
        },
    }),
);
